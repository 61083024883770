import React from "react";
import "./Skillsets.css";

const Skillsets = () => {
  return (
    <div className="skillsets">
      <h1>Skillsets Page</h1>
    </div>
  );
};

export default Skillsets;