import React from "react";
import "./Banner.css";

const Banner = () => {
  return (
    <div className="banner">
      <h1>Welcome SeanFallon.com Alpha V0.2.6</h1>
    </div>
  );
};

export default Banner;
