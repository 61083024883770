/* HomeGraphic.jsx v1.1 */
import React from "react";
import "./HomeGraphic.css";
// import profileImage from "../../assets/images/Me_cropped.png"; Import the image

const HomeGraphic = () => {
  return (
    <div className="graphic-container">
        <h1>Home Graphic</h1>
    </div>
  );
};

export default HomeGraphic;