import React from "react";
import "./Content.css";

const Content = () => {
  return (
    <div className="content-container">
      <h1>Content Page</h1>
    </div>
  );
};

export default Content;